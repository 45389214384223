/**
 * Creates a throttled function that only invokes func at most once per every wait milliseconds.
 *
 * @param fn
 * @param delay
 */
export function throttle<T, A extends unknown[], R>(
  fn: (...args: A) => R,
  delay: number,
) {
  let timer: ReturnType<typeof setTimeout> | null = null
  let savedArgs: A | null = null

  return function decorator(this: T, ...rest: A): void {
    if (timer) {
      savedArgs = rest
      return
    }

    fn.call(this, ...rest)

    timer = setTimeout(() => {
      if (savedArgs) {
        fn.call(this, ...savedArgs)
        savedArgs = null
      }

      clearTimeout(timer as ReturnType<typeof setTimeout>)
      timer = null
    }, delay)
  }
}
